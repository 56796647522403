import { render, staticRenderFns } from "./alipay-server-add.vue?vue&type=template&id=77d78d08&scoped=true&"
import script from "./alipay-server-add.vue?vue&type=script&lang=js&"
export * from "./alipay-server-add.vue?vue&type=script&lang=js&"
import style0 from "./alipay-server-add.vue?vue&type=style&index=0&id=77d78d08&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d78d08",
  null
  
)

export default component.exports